import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonModal } from "@ionic/react";
import { FC } from "react";
import "./ConfirmDialog.css";

interface ConfirmDialogProps {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  content: string | JSX.Element;
  onClose: () => void;
  onConfirm?: () => void;
  confirmButtonText?: string | JSX.Element;
  confirmButtonProps?: any;
};
const ConfirmDialog: FC<ConfirmDialogProps> = (dialog) => {
  const {
    isOpen,
    title,
    subtitle,
    content,
    onClose,
    onConfirm,
    confirmButtonText = 'Confirm',
    confirmButtonProps
  } = dialog;

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose={true}
      className="confirm-dialog"
    >
      <IonContent className="confirm-dialog-content">
        <IonCard>
          <IonCardHeader>
            {
              subtitle && (
                <IonCardSubtitle>
                  {subtitle}
                </IonCardSubtitle>
              )
            }
            {
              title && (
                <IonCardTitle>{title}</IonCardTitle>
              )
            }
          </IonCardHeader>
          <IonCardContent>
            <div>
              {content}
            </div>
            <div className="action-buttons-container">
              <IonButton
                color="light"
                onClick={() => {
                  onClose();
                }}
                className="no-pr-i no-pl-i"
                size="small"
              >
                Close
              </IonButton>
              {
                !['undefined', 'null'].includes(typeof onConfirm) && (
                  <IonButton
                    onClick={() => {
                      onConfirm();
                    }}
                    size="small"
                    className="no-pr-i no-pl-i"
                    {...confirmButtonProps}
                  >
                    {confirmButtonText}
                  </IonButton>
                )
              }
            </div>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonModal>
  );
}
export default ConfirmDialog;
