import { IonButton, IonContent, IonImg, IonPage } from '@ionic/react';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import "./ResidentSplashStyle.css";

const ResidentSplash: React.FC = () => {
  return (
    <IonPage className="page-welcome guest-screen-bg">
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <div className="main-content">
          <h1>Welcome to Slavik</h1>
          <IonButton
            color="primary"
            routerLink="/login"
          >
            Login
          </IonButton>
          <IonButton
            color="transparent"
            routerLink="/register"
          >
            I&apos;m A New Resident
          </IonButton>
        </div>
        <IonImg className="logo" src={'assets/images/slavikLogoWhite.png'} />
      </IonContent>
    </IonPage>
  );
};

export default ResidentSplash;
