import { IonContent, IonPage, IonText } from '@ionic/react';
import { FC } from 'react';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import useAuth from 'src/hooks/useAuth';
import './Messages.css';
import ResidentTabs from '../components/ResidentTabs';
import { useParams } from 'react-router';

const Message: FC = () => {
  const { notificationId } = useParams() as any;
  const { user: { user: { notifications, unit } } } = useAuth();

  if (!notificationId) {
    return <span />;
  }

  const notification = notifications.find((notif) => notif.Id == notificationId);

  return (
    <IonPage className="page-messages page-message">
      <HeaderToolbar
        backRouterLink="/app/messages"
        changeLogoToBack
      />
      <IonContent
        className="content-container"
        style={{
          height: 'calc(100% - 81px)'
        }}
      >
        <div className="main-content">
          <IonText color="primary" className="content-title">
            <h2>{notification.Subject}</h2>
          </IonText>
          <div className="mt-2">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: notification?.Body }}
            />
          </div>
        </div>
      </IonContent>
      <ResidentTabs />
    </IonPage>
  );
}

export default Message;
