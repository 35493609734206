import { faClock, faEnvelope, faFax, faLocationDot, faPhoneOffice } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonCol, IonContent, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import { FC } from "react";
import './ResidentAbout.css';
import { PropertyChildProps } from '../ResidentMain';

const ResidentAbout: FC<PropertyChildProps> = (props) => {
  const { property, userUnit } = props;

  const propertyAddress = (
    userUnit && userUnit.Property.Id === property.Id
      ? userUnit?.FormattedAddress
      : `${property.Address.Line1}${property.Address.Line2 ? ' ' + property.Address.Line2 : ''}, ${property.Address.City}, ${property.Address.State} ${property.Address.Zip}`
  ) || '';

  return (
    <IonContent className="content-resident-about content-resident">
      <a href={property.Facebook} target="_blank" rel="noopener noreferrer">
        <IonImg className="img-fb" src="assets/images/findUsOnFacebookButton.png" />
      </a>
      <div className="content-resident-main">
        <IonText color="primary" className="content-title">
          <h2>About</h2>
        </IonText>
        <IonText className="content-subtitle">
          <h5>{property.Name}</h5>
        </IonText>
        <div className="mt-2">
          <div className="group-text-icon">
            <div className="group-icon">
              <FontAwesomeIcon icon={faLocationDot} /><span className="text-icon">address</span>
            </div>
            <div className="group-text" dangerouslySetInnerHTML={{ __html: propertyAddress }} />
          </div>
          <IonGrid className="np">
            <IonRow>
              <IonCol>
                <div className="group-text-icon">
                  <div className="group-icon">
                    <FontAwesomeIcon icon={faPhoneOffice} /><span className="text-icon">phone</span>
                  </div>
                  <div className="group-text">
                    <a href={`tel:${property.Phone}`}>{property.Phone}</a>
                  </div>
                </div>
                <div className="group-text-icon">
                  <div className="group-icon">
                    <FontAwesomeIcon icon={faFax} /><span className="text-icon">fax</span>
                  </div>
                  <div className="group-text">
                    <a href={`tel:${property.Fax}`}>{property.Fax}</a>
                  </div>
                </div>
              </IonCol>
              <IonCol>
                {
                  property?.OfficeHours?.map((officeHour, idx) => (
                    <div
                      className="group-text-icon"
                      key={idx}
                    >
                      <div className="group-icon">
                        <FontAwesomeIcon icon={faClock} />
                        <span className="text-icon">{officeHour.days_of_the_week}</span>
                      </div>
                      <div className="group-text">
                        {`${officeHour.time_open} - ${officeHour.time_closed}`}
                      </div>
                    </div>
                  ))
                }
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <div className="group-text-icon">
                  <div className="group-icon">
                    <FontAwesomeIcon icon={faEnvelope} /><span className="text-icon">email</span>
                  </div>
                  <div className="group-text">
                    <a href={`mailto:${property.Email}`}>{property.Email}</a>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="description">
            <p>{property.Description}</p>
          </div>
        </div>
      </div>
    </IonContent>
  );
}

export default ResidentAbout;
