import { faHouseDay, faRightFromBracket, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonContent, IonItem, IonList, IonMenu, IonText } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import useAuth from "src/hooks/useAuth";
import { useSelector } from "src/store";
import "./Menu.css";

const Menu = () => {
  const { user: { user, openNotificationId }, isAuthenticated, getNotifications } = useAuth();
  const history = useHistory();
  const { properties } = useSelector((state) => state.appData);
  const menu = useRef<HTMLIonMenuElement>(null);
  const navigate = (to) => history.push(to);

  useEffect(() => {
    if (openNotificationId) {
      navigate(`/app/message/${openNotificationId}`);
    }
  }, [openNotificationId]);

  return (
    <IonMenu
      ref={menu}
      side="end"
      menuId="main"
      contentId="main"
      type="overlay"
      disabled={!isAuthenticated}
    >
      <IonContent className="main-menu-content">
        <div>
          <IonText color="light">
            <span className="fs-sx">Signed In:</span>
          </IonText>
          <IonText color="primary">
            <h4 className="user-name">{user?.display_name}</h4>
          </IonText>
        </div>
        <IonList lines="none">
          {/* <IonItem
            detail={false}
            onClick={() => {
              getNotifications(23645);
            }}
          >
            <FontAwesomeIcon icon={faUserCircle} />
            Test notification
          </IonItem> */}
          <IonItem
            routerLink="/app/profile"
            detail={false}
            onClick={() => menu.current?.close()}
          >
            <FontAwesomeIcon icon={faUserCircle} />
            Profile
          </IonItem>
          <IonItem
            routerLink="/app/resident/about"
            onClick={() => menu.current?.close()}
          >
            <FontAwesomeIcon icon={faHouseDay} />
            {user?.unit.Property.Name}
          </IonItem>
          <IonItem
            onClick={() => {
              menu.current?.close();
              navigate('/logout');
            }}
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
            Sign Out
          </IonItem>
        </IonList>
        <hr />
        <div>
          <IonText color="light">
            <span className="fs-sx">Explore</span>
          </IonText>
          <IonText color="primary">
            <h4 className="user-name">Our Communities</h4>
          </IonText>
        </div>
        <IonList lines="none">
          {
            properties
              .filter((property) => property.Id !== user?.unit.Property.Id)
              .map((property) => (
                <IonItem
                  routerLink={`/communities/${property.Id}`}
                  key={property.Id}
                  onClick={() => {
                    menu.current?.close();
                  }}
                >
                  {property.Name}
                </IonItem>
              ))
          }
        </IonList>
      </IonContent>
    </IonMenu>
  )
};

export default Menu;
