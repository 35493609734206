import { Route, Switch } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Welcome from './pages/Welcome/Welcome';
import ResidentSplash from './pages/ResidentSplash/ResidentSplash';
import ResidentLogin from './pages/ResidentLogin/ResidentLogin';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
/* Theme global */
import './theme/global.css';

import Menu from './components/Menu/Menu';
import GuestWelcome from './pages/GuestWelcome';
import ResidentRegister from './pages/ResidentRegister';
import { useDispatch } from './store';
import { useEffect } from 'react';
import { getAppData } from './store/reducers/appData';
import ProtectedRoute from './components/ProtectedRoute';
import Community from './pages/Communities/Community';
import CommunitiesSelect from './pages/Communities/CommunitiesSelect';
import Messages from './pages/app/Messages/Messages';
import ResidentMain from './pages/app/ResidentMain';
import Profile from './pages/app/Profile';
import ResidentWelcome from './pages/app/ResidentWelcome/ResidentWelcome';
import ReferAFriend from './pages/app/ReferAFriend';
import GuestRoute from './components/GuestRoute';
import Logout from './pages/Logout';
import OneSignalInit from './utils/oneSignalInit';
import ServiceRequest from './pages/app/ServiceRequest';
import useAuth from './hooks/useAuth';
import Message from './pages/app/Messages/Message';

setupIonicReact();

const App: React.FC = () => {
  const { getNotifications } = useAuth();
  const dispatch = useDispatch();

  const init = async () => {
    try {
      dispatch(getAppData());
      OneSignalInit({ getNotifications });
    } catch (error) {
      console.log('Caught Exception: ', error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet
            id="main"
            animated={false}
          >
            <GuestRoute
              exact
              path="/"
              component={Welcome}
            />
            <GuestRoute
              exact
              path="/resident-splash"
              component={ResidentSplash}
            />
            <GuestRoute
              exact
              path="/login"
              component={ResidentLogin}
            />
            <GuestRoute
              exact
              path="/register"
              component={ResidentRegister}
            />
            <Switch>
              <Route
                path="/communities/:communityId"
                render={() => (
                  <Community />
                )}
              />
              <ProtectedRoute
                path="/app/resident"
                component={ResidentMain}
              />
            </Switch>
            <Route
              path="/communities"
              render={() => <CommunitiesSelect />}
              exact
            />
            <Route
              exact
              path="/guest"
              render={() => (
                <GuestWelcome />
              )}
            />
            <ProtectedRoute
              path="/app/messages"
              component={Messages}
              exact
            />
            <ProtectedRoute
              path="/app/message/:notificationId"
              component={Message}
            />
            <ProtectedRoute
              path="/app/profile"
              component={Profile}
              exact
            />
            <ProtectedRoute
              path="/app/refer-a-friend"
              component={ReferAFriend}
              exact
            />
            <ProtectedRoute
              path="/app/service-request"
              component={ServiceRequest}
              exact
            />
            <ProtectedRoute
              path="/app"
              component={ResidentWelcome}
              exact={true}
            />
            <Route
              exact
              path="/logout"
              component={Logout}
            />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
