import { InputChangeEventDetail, IonButton, IonCheckbox, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonNote, IonPage, IonSpinner, IonTextarea, isPlatform, useIonToast } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import "../Profile/Profile.css";
import "./ServiceRequest.css";
import useAuth from 'src/hooks/useAuth';
import { apiPostServiceRequest } from 'src/api/properties';
import { SECRET_KEY } from 'src/config';
import { useHistory } from 'react-router';
import { camera } from 'ionicons/icons';
import { Camera, CameraResultType, GalleryPhoto, Photo } from '@capacitor/camera';

const symptomsArray = [
  'A fever of 100.4 degress Fahrenheit or higher',
  'A cough',
  'Shortness of Breath or Difficulty in Breathing',
  'Chills',
  'Muscle Aches',
  'Sore Throat',
  'Diarrhea',
  'Loss of taste or smell or a change in taste'
];

const ServiceRequest: React.FC = () => {
  const { user: { user } } = useAuth();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const [present] = useIonToast();
  const [photoSelected, setPhotoSelected] = useState<any>(null);
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    havePets: false,
    symptoms: [],
    traveled: false,
    inCloseContact: false
  });
  const defaultFormErrors = {
    name: [],
    phone: [],
    email: [],
    message: [],
    havePets: [],
  };
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    name,
    phone,
    email,
    havePets,
    message,
    symptoms,
    traveled,
    inCloseContact
  } = formValues;

  const setFormFieldValue = (key, value) => setFormValues({ ...formValues, [key]: value });

  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required'),
    phone: yup
      .string()
      .matches(/^[0-9]{10}$/, 'Invalid phone number')
      .required('Phone is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    havePets: yup
      .boolean(),
    message: yup
      .string()
      .required('Message is required'),
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        ...formValues,
        name: user.display_name,
        phone: user.phone,
        email: user.email,
      })
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    /* reset errors */
    setFormErrors(defaultFormErrors);
    setIsSubmitting(true);
    let valid = true;
    schema.validate(formValues, { abortEarly: false }).catch((error) => {
      let formValidationErrors = defaultFormErrors;

      error.inner.forEach((error: any) => {
        formValidationErrors[error.path] = [
          ...formValidationErrors[error.path],
          error.message
        ]
      });
      setFormErrors(formValidationErrors);

      setTimeout(function () {
        formRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 300);

      valid = false;

    }).then(async () => {

      if (valid) {
        // api call here
        try {
          setIsSubmitting(true);
          let Attachment = null;
          if (photoSelected) {
            Attachment = await fetch(photoSelected?.dataUrl)
              .then(r => r.blob())
              .then(blobFile => new File([blobFile], `${new Date().getTime()}.${photoSelected.format}`, { type: `image/${photoSelected.format}` }));
          }

          await apiPostServiceRequest({
            unitId: user?.unit.Id,
            formData: {
              Secret_Key: SECRET_KEY,
              Name: name,
              Phone: phone,
              Email: email,
              Message: message,
              Pets: havePets ? 'Yes' : 'No',
              Attachment,
              Travel: traveled ? 'Yes' : 'No',
              Diagnosed: inCloseContact ? 'Yes' : 'No',
              Symptoms: symptoms.length ? JSON.stringify(symptoms) : 'None'
            }
          }) as any;

          present({
            message: 'Success',
            duration: 3000,
            color: 'success'
          });
          setIsSubmitting(false);
          history.goBack();
        } catch (error) {
          present({
            message: error,
            duration: 3000,
            color: 'danger'
          });
          setIsSubmitting(false);
        }

      } else {
        // not valid, allow submit again
        setIsSubmitting(false);
      }

    });
  };

  return (
    <IonPage className="page-resident-profile">
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <div className="main-content">
          <h1>Service Request</h1>
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            method="post"
            action=""
            noValidate
          >
            <IonList>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Name*</IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('name', value);
                  }}
                  placeholder="Name*"
                />
                {
                  formErrors.name.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.name.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Phone*</IonLabel>
                <IonInput
                  value={phone}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('phone', value);
                  }}
                  placeholder="Phone*"
                />
                {
                  formErrors.phone.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.phone.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Email*</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('email', value);
                  }}
                  placeholder="Email*"
                />
                {
                  formErrors.email.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.email.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value no-mb-i">
                <IonLabel position="floating">Message*</IonLabel>
                <IonTextarea
                  value={message}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('message', value);
                  }}
                  placeholder="Message*"
                  rows={7}
                />
                {
                  formErrors.message.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.message.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-checkbox w-divider">
                <IonCheckbox
                  checked={havePets}
                  slot="start"
                  onIonChange={(e) => {
                    setFormFieldValue('havePets', e.detail.checked);
                  }}
                />
                <IonLabel>
                  Do you have pets?
                </IonLabel>
              </IonItem>
              <IonItem className="item-checkbox w-divider item-nested-checkbox no-mb-i">
                <div className="nested-checkbox">
                  <div>
                    <IonLabel className="no-ml-i">
                      Do you have any of the following symptoms?
                    </IonLabel>
                  </div>
                  <div>
                    <IonList>
                      {
                        symptomsArray.map((symptom, idx) => (
                          <IonItem
                            className="item-checkbox no-mb-i"
                            key={idx}
                          >
                            <IonCheckbox
                              slot="start"
                              onIonChange={(e) => {
                                if (symptom === 'None of the above' && e.detail.checked) {
                                  setFormFieldValue('symptoms', []);
                                } else {
                                  if (e.detail.checked) {
                                    setFormFieldValue('symptoms', [...symptoms, symptom]);
                                  } else {
                                    setFormFieldValue('symptoms', symptoms.filter((s) => s !== symptom));
                                  }
                                }
                              }}
                            />
                            <IonLabel>
                              {symptom}
                            </IonLabel>
                          </IonItem>
                        ))
                      }
                    </IonList>
                  </div>
                </div>
              </IonItem>
              <IonItem className="item-checkbox w-divider no-mb-i">
                <IonCheckbox
                  checked={traveled}
                  slot="start"
                  onIonChange={(e) => {
                    setFormFieldValue('traveled', e.detail.checked);
                  }}
                />
                <IonLabel>
                  Have you traveled in the past 14 days to regions affected by COVID-19?
                </IonLabel>
              </IonItem>
              <IonItem className="item-checkbox w-divider">
                <IonCheckbox
                  checked={inCloseContact}
                  slot="start"
                  onIonChange={(e) => {
                    setFormFieldValue('inCloseContact', e.detail.checked);
                  }}
                />
                <IonLabel>
                  Have you been in close contact with anyone who has a confirmed COVID-19 diagnoses?
                </IonLabel>
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Attach Photos</IonLabel>
                <IonButton
                  className="button-fullwidth button-attachment"
                  onClick={async () => {
                    if (isPlatform('android') || isPlatform('tablet')) {
                      const photos = await Camera.pickImages({
                        limit: 1,
                        quality: 70,
                      });
                      if (photos.photos.length) {
                        const photo = photos.photos[0]
                        setPhotoSelected({
                          dataUrl: photo.webPath,
                          format: photo.format
                        });
                      }
                    } else {
                      const photo = await Camera.getPhoto({
                        resultType: CameraResultType.DataUrl,
                        allowEditing: false,
                        quality: 70,
                      });

                      setPhotoSelected(photo);
                    }
                  }}
                  size="default"
                >
                  <IonIcon slot="start" icon={camera} />
                  {isPlatform('android') || isPlatform('tablet') ? 'Open Gallery' : 'Open Camera / Gallery'}
                </IonButton>
                {photoSelected?.dataUrl && (<IonImg src={photoSelected.dataUrl} />)}
              </IonItem>
            </IonList>
            <div className="form-action">
              <IonButton
                type="submit"
                color="primary"
                size="small"
                disabled={isSubmitting}
              >
                {
                  isSubmitting
                    ? (
                      <IonSpinner name="dots" />
                    )
                    : 'Submit'
                }
              </IonButton>
            </div>
          </form>
        </div>
        <IonImg className="logo" src={'assets/images/slavikLogoBunker.png'} />
      </IonContent>
    </IonPage >
  );
};

export default ServiceRequest;
