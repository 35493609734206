import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";
import { FC, useEffect, useState } from "react";
import { apiPost } from "src/api/api";
import useAuth from "src/hooks/useAuth";
import timeFromNow from "src/utils/timeFromNow";
import { closeOutline } from "ionicons/icons";
import "./MessageItem.css";

const truncateWithEllipses = (text, max) =>
  text.substr(0, max - 1) + (text.length > max ? "&hellip;" : "");

export interface MessageItemProps {
  Subject: string;
  Summary: string;
  Body: string;
  PostDate: number;
  Viewed?: boolean;
  Id: number;
}

const MessageItem: FC<MessageItemProps> = ({
  Subject,
  Summary,
  Body,
  PostDate,
  Viewed,
  Id,
}) => {
  const {
    user: { cookie },
  } = useAuth();
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [isViewed, setIsViewed] = useState<boolean>(false);

  useEffect(() => {
    setIsViewed(Viewed);
  }, []);

  useEffect(() => {
    if (openMessage && !isViewed) {
      setIsViewed(true);
      apiPost({
        targetUrl: "user/notification_status",
        formData: {
          cookie,
          notification_id: Id,
          status: "viewed",
        },
        nonce: {
          method: "notification_status",
        },
      });
    }
  }, [openMessage]);

  const timeFromData = timeFromNow(PostDate);
  const timeFromFormatted = `${timeFromData.time} ${timeFromData.unitOfTime} ${
    timeFromData.when === "past" ? "ago" : ""
  }`;

  return (
    <>
      <IonItem
        className="message-item"
        onClick={() => setOpenMessage(!openMessage)}
        // routerLink={`/app/message/${Id}`}
      >
        <IonGrid>
          <IonRow className="message-header">
            <IonCol size="8" className="subject">
              <IonText color="dark">{Subject}</IonText>
            </IonCol>
            <IonCol size="4" className="timestamp">
              <IonText color="secondary">{timeFromFormatted} &gt;</IonText>
            </IonCol>
          </IonRow>
          <IonRow className="message-content">
            <IonCol size="10" className="content">
              <IonText color="dark">
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncateWithEllipses(Summary, 150),
                  }}
                />
              </IonText>
            </IonCol>
            <IonCol size="2" className="status-dot">
              {!isViewed && <FontAwesomeIcon icon={faCircle} />}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={openMessage}
          swipeToClose={true}
          className="message-item-modal"
        >
          <IonContent className="message-item-modal-content">
            <IonCard>
              <IonCardHeader>
                <IonCardSubtitle>
                  <span>{timeFromFormatted}</span>
                  <a
                    href="#"
                    className="btn-icon-only"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenMessage(false);
                    }}
                  >
                    <IonIcon slot="icon-only" icon={closeOutline} />
                  </a>
                </IonCardSubtitle>
                <IonCardTitle>{Subject}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <div
                  className="message-item-modal-content-body"
                  dangerouslySetInnerHTML={{
                    __html: Body,
                  }}
                />
                <div
                  className="mt-3"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <IonButton
                    color="light"
                    onClick={() => {
                      setOpenMessage(false);
                    }}
                  >
                    Close
                  </IonButton>
                </div>
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>
      </IonItem>
    </>
  );
};

export default MessageItem;
