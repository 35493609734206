import { apiGet, apiPost } from "./api"


export const apiGetProperties = async (): Promise<any> => {
  return apiGet({
    targetUrl: 'properties/properties/'
  });
}

export const apiGetProperty = async (propertyId): Promise<any> => {
  return apiGet({
    targetUrl: `properties/${propertyId}/`
  });
}

export const apiPostReferAFriend = async ({ unitId, formData }): Promise<any> => {
  return apiPost({
    targetUrl: `units/${unitId}/refer-a-friend/`,
    formData
  });
}

export const apiPostServiceRequest = async ({ unitId, formData }): Promise<any> => {
  return apiPost({
    targetUrl: `units/${unitId}/service-request/`,
    formData
  });
}

export const apiPostPropertyInterested = async ({ formData }): Promise<any> => {
  return apiPost({
    targetUrl: `properties/send_interested_request/`,
    formData
  });
}
