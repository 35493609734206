import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { useHistory } from "react-router";
import { Browser } from '@capacitor/browser';
import './Tabs.css';
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

type tabObject = {
  name: string;
  faIcon?: any;
  onClick?: (...args: any) => void;
  navigateTo?: string;
  href?: string;
  hasStatus?: boolean;
}
interface TabsProps {
  tabs: tabObject[];
}
const Tabs: FC<TabsProps> = (props) => {
  const { tabs } = props;
  const history = useHistory();
  const navigate = (to) => history.push(to);

  return (
    <div className="app-tabs">
      {
        tabs.map((tab, idx) => {
          const { href, navigateTo, onClick } = tab;

          const buttonProps = {} as any;
          if (href || navigateTo || onClick) {
            buttonProps.onClick = async (e) => {
              e.preventDefault();
              if (navigateTo) {
                navigate(navigateTo);
              } else if (href) {
                if (
                  ['tel:', 'mailto:'].some(
                    (notUrl) => href.includes(notUrl)
                  )
                ) {
                  window.open(href, '_system');
                } else {
                  await Browser.open({ url: href });
                }
              }
            }
          }


          return (
            <div
              key={idx}
              className="app-tab-item"
              style={{
                flexBasis: `${100 / tabs.length}%`
              }}
            >
              <button
                className="group-button-icon"
                {...buttonProps}
              >
                {
                  (
                    <span className="group-icon">
                      <FontAwesomeIcon className="main-icon" icon={tab.faIcon} />
                      {
                        tab.hasStatus && (
                          <FontAwesomeIcon className="icon-msg-notif" icon={faCircle} />
                        )
                      }
                    </span>
                  )
                }
                <span className="group-text">{tab.name}</span>
              </button>
            </div>
          );
        })
      }
    </div>
  );
}

export default Tabs;
