import { faHouseDay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonContent, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { FC } from "react";
import { Redirect, Route, useParams } from "react-router";
import HeaderToolbar from "src/components/HeaderToolbar/HeaderToolbar";
import useAuth from "src/hooks/useAuth";
import { useSelector } from "src/store";
import ResidentAbout from "./ResidentAbout";
import ResidentFeatures from "./ResidentFeatures";
import ResidentGallery from "./ResidentGallery";
import './ResidentMain.css';
import ResidentSizePrice from "./ResidentSizePrice";
import { Browser } from '@capacitor/browser';
import ResidentTabs from "../components/ResidentTabs";

export interface PropertyChildProps {
  property?: any;
  userUnit?: any;
};

const ResidentMain: FC = () => {
  const { properties } = useSelector((state) => state.appData);
  const { communityId } = useParams() as any;
  const { user: { user } } = useAuth();

  const property = user?.community || properties?.find((property) => property.Id === communityId);

  const childProps = {
    property,
    userUnit: user?.unit || null
  };

  return (
    <IonContent
      className="page-resident-main"
      scrollY={false}
    >
      <HeaderToolbar
        headerTitle={user.unit.Property.Name}
        headerTitleIcon={<FontAwesomeIcon icon={faHouseDay} />}
      />
      <IonContent
        className="content-container"
        style={{
          height: 'calc(100% - 81px)'
        }}
      >
        <IonTabs className="sub-tabs">
          <IonRouterOutlet>
            <Route
              path="/app/resident/gallery"
              render={() => <ResidentGallery {...childProps} />}
              exact
            />
            <Route
              path="/app/resident/size-price"
              render={() => <ResidentSizePrice {...childProps} />}
              exact
            />
            <Route
              path="/app/resident/about"
              render={() => <ResidentAbout {...childProps} />}
              exact
            />
            <Route
              path="/app/resident/features"
              render={() => <ResidentFeatures {...childProps} />}
              exact
            />
            <Route
              path="/app/resident"
              render={() => <Redirect to="/app/resident/about" />}
              exact
            />
          </IonRouterOutlet>
          <IonTabBar
            slot="top"
            onIonTabsDidChange={async (tabEvent: any) => {
              const { tab } = tabEvent.detail;
              if (['http', 'tel', 'email'].some((urlStart) => tab.includes(urlStart))) {
                await Browser.open({ url: tab });
              }
            }}
          >
            <IonTabButton
              tab="resident"
              href="/app/resident/about"
            >
              About
            </IonTabButton>
            <IonTabButton
              tab="residentSizePrice"
              href="/app/resident/size-price"
            >
              Size/Price
            </IonTabButton>
            <IonTabButton
              tab="residentFeatures"
              href="/app/resident/features"
            >
              Features
            </IonTabButton>
            <IonTabButton
              tab="residentGallery"
              href="/app/resident/gallery"
            >
              Gallery
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonContent>
      <ResidentTabs />
    </IonContent>
  );
}

export default ResidentMain;
