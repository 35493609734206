import { faBed, faBedFront, faHouseChimneyHeart, faHouseChimneyWindow } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonCol, IonContent, IonGrid, IonRow, IonText } from "@ionic/react";
import { FC } from "react";
import { PropertyChildProps } from '../ResidentMain';
import './ResidentSizePrice.css';
import { Browser } from '@capacitor/browser';
import { APP_URL } from "src/config";

const getIcon = (floorPlan) => {
  switch (floorPlan.apartment_size) {
    case '2 Bedroom':
    case 'Deluxe 1 Bedroom':
    case '1 Bedroom Chalet':
      return faBedFront;

    case '2 Bedroom Townhouse':
      return faHouseChimneyHeart;

    case '3 Bedroom Townhouse':
      return faHouseChimneyWindow;

    default:
      return faBed;
  }
};

const ResidentSizePrice: FC<PropertyChildProps> = (props) => {
  const { property } = props;

  const sizePrice = property?.FloorPlans?.map((floorPlan) => {
    const plan = {
      name: floorPlan.apartment_size,
      icon: getIcon(floorPlan),
      priceRange: `$${floorPlan.price_range_low} - $${floorPlan.price_range_high}`,
      size: `${floorPlan.square_footage_high} sq ft`,
      bath: `${floorPlan.bathrooms} bath`
    };

    return plan;
  }) || [];

  return (
    <IonContent className="content-resident-sizeprice content-resident">
      <div className="content-resident-main">
        <IonText color="primary" className="content-title">
          <h2>Size/Price</h2>
        </IonText>
        <div className="mt-2">
          <IonGrid className="table-grid">
            {
              sizePrice.map((detail, idx) => (
                <IonRow
                  key={idx}
                  className="ion-align-items-center"
                >
                  <IonCol
                    className="col-icon"
                    size="3"
                  >
                    <div className="col-body">
                      <div className="group-text-icon">
                        <div className="group-icon">
                          <span className="icon-circle">
                            <FontAwesomeIcon icon={detail.icon} />
                          </span>
                        </div>
                        <div className="group-text">
                          {detail.name}
                        </div>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="col-body">
                      <span className="col-label">PRICE*</span>
                      <div>{detail.priceRange}</div>
                    </div>
                  </IonCol>
                  <IonCol class="3">
                    <div className="col-body">
                      <span className="col-label">SIZE</span>
                      <div>{detail.size}</div>
                    </div>
                  </IonCol>
                  <IonCol class="2">
                    <div className="col-body">
                      <span className="col-label">BATH</span>
                      <div>{detail.bath}</div>
                    </div>
                  </IonCol>
                </IonRow>
              ))
            }
          </IonGrid>
        </div>
        <div className="mt-2">
          <div className="bottom-buttons">
            <IonButton
              color="light"
              onClick={async () => {
                await Browser.open({ url: `${APP_URL}/properties/schedule-an-appointment/?property_id=${property.Id}` });
              }}
            >
              Schedule Visit
            </IonButton>
            <IonButton
              color="primary"
              onClick={async () => {
                await Browser.open({ url: `${APP_URL}/apply-for-residency/?property_id=${property.Id}` });
              }}
            >
              Apply Online
            </IonButton>
          </div>
        </div>
      </div>
    </IonContent>
  );
}

export default ResidentSizePrice;
