import { createContext, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import { apiGetNonce, apiPost } from 'src/api/api';
import { Storage } from '@capacitor/storage';
import { ERROR_GENERIC } from 'src/constants';
import { isEmpty } from 'lodash';
import { apiGetProperty } from 'src/api/properties';
import OneSignal from 'onesignal-cordova-plugin';
import { isPlatform } from '@ionic/react';
import { faCropSimple } from '@fortawesome/pro-solid-svg-icons';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: any;
}

interface AuthContextValue extends State {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (data: any) => Promise<void>;
  update: (data: any) => Promise<void>;
  validateCookie: () => Promise<void>;
  getNotifications: (notificaiontId?: number) => Promise<void>;
  resetOpenedNotification: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: any;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: any;
  };
};

type GetNotificationAction = {
  type: 'GET_NOTIFICATIONS';
  payload: {
    openNotificationId?: number
    notifications: any;
  };
};

type ResetOpenedNotificationAction = {
  type: 'RESET_OPENED_NOTIFICATION'
}

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type UpdateAction = {
  type: 'UPDATE';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateAction
  | GetNotificationAction
  | ResetOpenedNotificationAction

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: {
    user: null
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  GET_NOTIFICATIONS: (state: State, action: GetNotificationAction): State => {
    const { notifications, openNotificationId } = action.payload;
    let additionalState = {};

    if (openNotificationId) {
      additionalState = {
        openNotificationId
      };
    }

    return {
      ...state,
      user: {
        ...state.user,
        ...additionalState,
        notifications
      }
    };
  },
  RESET_OPENED_NOTIFICATION: (state: State): State => {
    return {
      ...state,
      user: {
        ...state.user,
        openNotificationId: null
      }
    };

  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: initialState.user
  }),
  REGISTER: (state: State): State => ({ ...state }),
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
  validateCookie: () => Promise.resolve(),
  getNotifications: (notificaiontId?: number) => Promise.resolve(),
  resetOpenedNotification: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getNotifications = async (openNotificationId?: number): Promise<any> => {
    return new Promise(async (resolve, reject) => {

      const cookie = await Storage.get({ key: 'authUser' });
      if (cookie?.value) {
        try {
          const authUser = JSON.parse(cookie?.value);
          const notificationsResponse = await apiPost({
            targetUrl: 'user/notifications/',
            formData: {
              cookie: authUser?.cookie
            },
            nonce: {
              method: 'notifications'
            }
          });

          dispatch({
            type: 'GET_NOTIFICATIONS',
            payload: {
              notifications: notificationsResponse?.data || [],
              openNotificationId
            }
          });

          resolve(notificationsResponse?.data || []);
        } catch (error) {
          reject('There was an error');
        }
      }
    });
  };

  const resetOpenedNotification = async (): Promise<any> => {
    return new Promise(async (resolve) => {
      dispatch({
        type: 'RESET_OPENED_NOTIFICATION'
      });
      resolve(true);
    });
  }

  const validateCookie = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const cookie = await Storage.get({ key: 'authUser' });
      if (cookie?.value) {
        try {
          const authUser = JSON.parse(cookie?.value);
          const userDataResponse = await apiPost({
            targetUrl: 'user/get_currentuserinfo/',
            formData: {
              cookie: authUser?.cookie
            }
          });

          if (!isEmpty(userDataResponse) && userDataResponse.status === 'ok') {
            const userData = {
              cookie: authUser?.cookie,
              user: {
                ...authUser?.user,
                ...userDataResponse.user
              }
            };
            const propertyResponse = await apiGetProperty(authUser.user.unit.Property.Id);
            userData.user.community = propertyResponse.data;

            const notificationsResponse = await apiPost({
              targetUrl: 'user/notifications/',
              formData: {
                cookie: authUser?.cookie
              },
              nonce: {
                method: 'notifications'
              }
            });
            userData.user.notifications = notificationsResponse.data;

            dispatch({
              type: 'LOGIN',
              payload: {
                user: userData
              }
            });

            resolve(userData);
          } else if (userDataResponse.status === 'error') {
            reject(userDataResponse.error)
          }
        } catch (error) {
          reject('error');
        }
      }
      reject('No cookie');
    });
  };

  const login = async (email: string, password: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const nonceData = await apiGetNonce({});

      const userData = await apiPost({
        targetUrl: 'user/generate_auth_cookie/',
        formData: {
          username: email,
          password: password,
          nonce: nonceData.nonce
        }
      });

      if (
        userData.cookie
        && userData.status === 'ok'
        && !isEmpty(userData.user.unit)
      ) {

        if ( isPlatform('capacitor') ) {
          OneSignal.setEmail( email );
        }

        await Storage.set({
          key: 'authUser',
          value: JSON.stringify({
            cookie: userData.cookie,
            user: {
              phone: userData.user.phone,
              unit: userData.user.unit
            }
          })
        });

        dispatch({
          type: 'LOGIN',
          payload: {
            user: userData
          }
        });
        resolve(userData);
      } else {
        if (userData.status === 'ok') {
          reject('Invalid user!');
        } else {
          reject(userData?.error || userData?.status || ERROR_GENERIC);
        }
      }
    });
  };

  const logout = async (): Promise<void> => {
    await Storage.remove({ key: 'authUser' });

    if ( isPlatform('capacitor') ) {
      OneSignal.logoutEmail();
    }

    dispatch({ type: 'LOGOUT' });
  };

  const register = async (data: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const nonceData = await apiGetNonce({
        method: 'register'
      });

      const {
        display_name,
        phone,
        email,
        password,
        community_id,
        unit_id
      } = data;

      const userData = await apiPost({
        targetUrl: 'user/register',
        formData: {
          nonce: nonceData.nonce,
          display_name,
          phone,
          email,
          password,
          community_id,
          unit_id,
        }
      });

      if (userData.cookie && userData.status === 'ok') {
        resolve(login(email, password));
      } else {
        reject(userData?.error || userData?.status || ERROR_GENERIC);
      }
    });
  };

  const update = async (data: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const nonceData = await apiGetNonce({
        method: 'update'
      });

      const {
        display_name,
        phone,
        email,
        community_id,
        unit_id,
        cookie
      } = data;

      let append = {};
      if (data.password) {
        append = {
          ...append,
          password: data.password
        };
      }

      const userData = await apiPost({
        targetUrl: 'user/update',
        formData: {
          nonce: nonceData.nonce,
          display_name,
          phone,
          email,
          community_id,
          unit_id,
          cookie,
          ...append
        }
      });

      if (userData.status === 'ok') {
        dispatch({
          type: 'UPDATE'
        });
        resolve(userData);
      } else {
        reject(userData?.error || userData?.status || ERROR_GENERIC);
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        update,
        validateCookie,
        getNotifications,
        resetOpenedNotification
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
