import { FC } from 'react';
import Tabs from "src/components/Tabs";
import { faBriefcase, faCreditCardFront, faMailbox, faPhoneOffice } from "@fortawesome/pro-solid-svg-icons";
import useAuth from 'src/hooks/useAuth';

const ResidentTabs: FC = () => {
  const { user: { user } } = useAuth();

  return (
    <Tabs
      tabs={[
        {
          name: 'Messages',
          faIcon: faMailbox,
          hasStatus: user.notifications.some((notif) => !notif.isViewed),
          navigateTo: '/app/messages'
        },
        {
          name: 'Pay Rent',
          faIcon: faCreditCardFront,
          href: user?.unit.Property.PayURL
        },
        {
          name: 'Call Us',
          faIcon: faPhoneOffice,
          href: `tel:${user?.community?.Phone}`
        },
        {
          name: 'Service',
          faIcon: faBriefcase,
          href: user?.community?.MaintenanceRequestsLink
        },
      ]}
    />
  )
};
export default ResidentTabs;