import React, { ComponentType } from "react";
import { Route, RouteProps, Redirect } from "react-router";
import useAuth from "src/hooks/useAuth";

interface OwnProps extends RouteProps {
  component: ComponentType
}

const GuestRoute: React.FC<OwnProps> = ({ component: Component, path, exact = false }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route path={path} exact={exact} render={
      (props) => (
        isAuthenticated
          ? <Redirect to={{ pathname: '/app', state: { from: props.location } }} />
          : <Component />
      )
    } />
  );
};

export default GuestRoute;
