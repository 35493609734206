import { IonButton, IonContent, IonImg, IonLoading, IonPage, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import { APP_VERSION } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import awaitCallback from 'src/utils/awaitCallback';
import "./WelcomeStyle.css";

const Welcome: React.FC = () => {
  const history = useHistory();
  const { validateCookie } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = (to) => history.push(to);

  const initLogin = async () => {
    setIsLoading(true);
    try {
      await validateCookie();
      await awaitCallback(() => setIsLoading(false));
      navigate('/app');
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initLogin();
  }, []);

  return (
    <IonPage className="page-welcome guest-screen-bg">
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <IonLoading
          isOpen={isLoading}
          message={'Loading...'}
        />
        <div className="main-content">
          <h1>Welcome to Slavik</h1>
          <IonButton
            color="primary"
            routerLink="/resident-splash"
          >
            I&apos;m A Resident
          </IonButton>
          <IonButton
            color="transparent"
            routerLink="/guest"
          >
            Continue As Guest
          </IonButton>
        </div>
        <div className="footer">
          <IonImg className="logo" src={'assets/images/slavikLogoWhite.png'} />
        </div>
        
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
