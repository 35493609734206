import { InputChangeEventDetail, IonButton, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonNote, IonPage, IonSpinner, useIonToast } from '@ionic/react';
import { useRef, useState } from 'react';
import * as yup from 'yup';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import "./ResidentLoginStyle.css";
import { useHistory } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { eye, eyeOff } from 'ionicons/icons';
import { Browser } from '@capacitor/browser';
import { APP_URL } from 'src/config';
import awaitCallback from 'src/utils/awaitCallback';

const ResidentLogin: React.FC = () => {
  const { login } = useAuth();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const [present] = useIonToast();
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const defaultFormErrors = {
    email: [],
    password: []
  };
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { email, password } = formValues;

  const setFormFieldValue = (key, value) => setFormValues({ ...formValues, [key]: value });

  const navigate = (to) => history.push(to);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: yup
      .string()
      .max(255)
      .required('Password is required')
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    /* reset errors */
    setFormErrors(defaultFormErrors);
    setIsSubmitting(true);
    let valid = true;
    schema.validate(formValues, { abortEarly: false }).catch((error) => {
      let formValidationErrors = defaultFormErrors;

      error.inner.forEach((error: any) => {
        formValidationErrors[error.path] = [
          ...formValidationErrors[error.path],
          error.message
        ]
      });
      setFormErrors(formValidationErrors);

      setTimeout(function () {
        formRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 300);

      valid = false;

    }).then(async () => {

      if (valid) {
        // api call here
        try {
          await login(email, password);
          await awaitCallback(() => setIsSubmitting(true));

          navigate('/app');
        } catch (error) {
          present({
            message: error,
            duration: 3000,
            color: 'danger'
          });
          setIsSubmitting(false);
        }

      } else {
        // not valid, allow submit again
        setIsSubmitting(false);
      }

    });
  }

  return (
    <IonPage
      className="page-resident-login guest-screen-bg"
    >
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <div className="main-content">
          <h1>Resident Login.</h1>
          {/* For later */}
          {/* <IonLoading isOpen={showLoading} message="Logging in..." onDidDismiss={() => setShowLoading(false)} /> */}
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            method="post"
            action=""
            noValidate
          >
            <IonList>
              <IonItem aria-errormessage="Error asdasdasd">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  autofocus
                  type="email"
                  value={email}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('email', value);
                  }}
                  placeholder="Email"
                />
                {
                  formErrors.email.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.email.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem>
                <div className="item-with-action">
                  <div className="col-input">
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                        const { value } = e.target as HTMLInputElement;
                        setFormFieldValue('password', value);
                      }}
                      placeholder="Password"
                    />
                  </div>
                  <div className="col-action">
                    <a
                      href="#"
                      className="btn-icon-only light"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={showPassword ? eye : eyeOff}
                      />
                    </a>
                  </div>
                </div>
                {
                  formErrors.password.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.password.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
            </IonList>
            <div className="forgot-password-container">
              <a
                href="#"
                onClick={async (e) => {
                  e.preventDefault();
                  await Browser.open({ url: `${APP_URL}/wp-login.php?action=lostpassword` });
                }}
              >
                Forgot Password?
              </a>
            </div>
            <div className="form-action">
              <IonLoading
                isOpen={isSubmitting}
                message={'Logging in...'}
              />
              <IonButton
                type="submit"
                color="transparent"
                size="small"
                className="no-app-style"
                disabled={isSubmitting}
              >
                {
                  isSubmitting
                    ? (
                      <IonSpinner name="dots" />
                    )
                    : 'Log in'
                }
              </IonButton>
            </div>
            {/* hide faceId */}
            {/* <div>
              <IonButton
                color="transparent"
                className="no-app-style"
              >
                <IonImg
                  className="img-faceid"
                  src={'assets/images/faceIdWhite.png'}
                />
              </IonButton>
            </div> */}
          </form>
        </div>
        <IonImg className="logo" src={'assets/images/slavikLogoWhite.png'} />
      </IonContent>
    </IonPage >
  );
};

export default ResidentLogin;
