import OneSignal from 'onesignal-cordova-plugin';
import { isPlatform } from '@ionic/react';
import { ONE_SIGNAL_APP_ID, ENV } from 'src/config';

// Call this function when your app starts
const OneSignalInit = ({ getNotifications }): void => {

  // We only want to init this on actual devices
  if ( isPlatform('capacitor') ) {

    if ( ENV === 'development') {
      OneSignal.setLogLevel( 6, 4 );
    } else {
      OneSignal.setLogLevel( 6, 0 );
    }

    OneSignal.setAppId(ONE_SIGNAL_APP_ID);

    // Prompts the user for notification permissions.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });

    // Handle incoming notification.
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      if (jsonData.notification?.additionalData) {
        const { additionalData }: any = jsonData.notification;
        if (additionalData?.alert_id) {
          getNotifications(additionalData?.alert_id);
        }
      }
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

  }
}
export default OneSignalInit;
