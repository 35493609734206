import { IonButton, IonContent, IonImg, IonPage } from '@ionic/react';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import { APP_URL } from 'src/config';
import './GuestWelcome.css';

const GuestWelcome: React.FC = () => {
  return (
    <IonPage className="page-welcome guest-screen-bg">
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <div className="main-content">
          <h1>I want to:</h1>
          <IonButton
            color="primary"
            routerLink="/communities"
          >
            Look at Apartments
          </IonButton>
          <IonButton
            color="transparent"
            href={`${APP_URL}/apply-for-residency/`}
            target="_blank"
          >
            Apply Online
          </IonButton>
        </div>
        <IonImg className="logo" src={'assets/images/slavikLogoWhite.png'} />
      </IonContent>
    </IonPage>
  );
};

export default GuestWelcome;
