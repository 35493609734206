import { faHouseDay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonPage } from '@ionic/react';
import { FC, useEffect, useRef, useState } from 'react';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import MessageItem from 'src/components/MessageItem';
import useAuth from 'src/hooks/useAuth';
import './Messages.css';
import ResidentTabs from '../components/ResidentTabs';

const increment = 10;
const Messages: FC = () => {
  const { user: { user: { notifications, unit }, }, getNotifications } = useAuth();
  const [maxIndex, setMaxIndex] = useState<number>(10);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const loaded = useRef<boolean>(false);

  const loadMoreData = (e) => {
    let addMore = maxIndex + increment;
    if (addMore > notifications.length) {
      addMore = notifications.length;
      setInfiniteDisabled(true);
    }
    setMaxIndex(addMore);
    e.target.complete();
  }

  const refresh = async () => {
    if (!loaded.current) {
      await getNotifications();
      loaded.current = true;
    }
  }

  useEffect(() => {
    refresh();
  }, []);

  return (
    <IonPage className="page-messages">
      <HeaderToolbar
        headerTitle={unit.Property.Name}
        headerTitleIcon={<FontAwesomeIcon icon={faHouseDay} />}
      />
      <IonContent
        className="content-container"
        style={{
          height: 'calc(100% - 81px)'
        }}
      >
        <IonList>
          {
            notifications
              .filter((msg, idx) => idx < maxIndex)
              .map((message, idx) => (
                <MessageItem
                  key={idx}
                  {...message}
                />
              ))
          }
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={loadMoreData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <ResidentTabs />
    </IonPage>
  );
}

export default Messages;
