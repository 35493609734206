import { IonContent, IonImg, IonText } from "@ionic/react";
import { FC } from "react";
import './ResidentFeatures.css';
import { PropertyChildProps } from '../ResidentMain';

const ResidentFeatures: FC<PropertyChildProps> = (props) => {
  const { property } = props;

  return (
    <IonContent className="content-resident-about content-resident">
      <a href={property.Facebook} target="_blank" rel="noopener noreferrer">
        <IonImg className="img-fb" src="assets/images/findUsOnFacebookButton.png" />
      </a>
      <div className="content-resident-main">
        <IonText color="primary" className="content-title">
          <h2>Features</h2>
        </IonText>
        <IonText className="content-subtitle">
          <h5>{property.Name}</h5>
        </IonText>
        <div className="mt-2">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: property?.Features }}
          />
        </div>
      </div>
    </IonContent>
  );
}

export default ResidentFeatures;
