import { IonButton, IonContent, IonPage } from "@ionic/react";
import HeaderToolbar from "src/components/HeaderToolbar/HeaderToolbar";
import useAuth from "src/hooks/useAuth";
import './ResidentWelcome.css';
import { Browser } from '@capacitor/browser';
import ResidentTabs from "../components/ResidentTabs";
import { useHistory } from "react-router";

const ResidentWelcome: React.FC = () => {
  const { user: { user } } = useAuth();
  const history = useHistory();
  const { unit, community } = user;

  const navigate = (to) => history.push(to);
  return (
    <IonPage className="page-resident-welcome">
      <HeaderToolbar />
      <IonContent
        className="content-container"
      >
        <IonContent
          style={{
            height: 'calc(100% - 81px)'
          }}>
          <div className="main-content">
            <h1 className="no-mb">Hi, {user.display_name}</h1>
            <div>What would you like to do?</div>
            <div className="mt-3">
              <IonButton
                color="primary"
                onClick={async () => {
                  await Browser.open({ url: unit.Property.PayURL });
                }}
              >
                Pay Rent
              </IonButton>
              <IonButton
                color="transparent"
                onClick={async () => {
                  if (community.MaintenanceRequestsLink) {
                    await Browser.open({ url: community.MaintenanceRequestsLink });
                  } else {
                    navigate('/app/service-request');
                  }
                }}
              >
                Service Request
              </IonButton>
              <IonButton
                color="transparent"
                routerLink="/app/refer-a-friend"
              >
                Refer A Friend
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonContent>
      <ResidentTabs />
    </IonPage>
  );
}

export default ResidentWelcome;
