import { IonContent, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { Route, useParams } from "react-router";
import HeaderToolbar from "src/components/HeaderToolbar/HeaderToolbar";
import ResidentFeatures from "src/pages/app/ResidentMain/ResidentFeatures";
import ResidentGallery from "src/pages/app/ResidentMain/ResidentGallery";
import { faBookmark, faFilePen, faPhoneOffice, faWindow } from '@fortawesome/pro-solid-svg-icons';
import 'src/pages/app/ResidentMain/ResidentMain.css';
import ResidentSizePrice from "src/pages/app/ResidentMain/ResidentSizePrice";
import { useSelector } from "src/store";
import { Browser } from '@capacitor/browser';
import { FC, useEffect, useState } from "react";
import Tabs from "src/components/Tabs";
import CommunityInterested from "../Interested/Interested";
import { APP_URL } from "src/config";
import { apiGetProperty } from "src/api/properties";
import CommunityAbout from "./CommunityAbout";

interface CommunityProps {
  setSelectedCommunity?: (property: any) => void;
}
const Community: FC<CommunityProps> = (props) => {
  const { communityId } = useParams() as any;
  const { properties } = useSelector((state) => state.appData);
  const [fullProperty, setProperty] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getFullProperty = async (propertyId) => {
    setIsLoading(true);
    const propertyResponse = await apiGetProperty(propertyId);
    if (propertyResponse.data) {
      setProperty(propertyResponse.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (communityId) {
      getFullProperty(communityId);
    }
  }, [communityId]);

  const property = fullProperty || properties?.find((property) => property.Id === parseInt(communityId));
  const childProps = {
    property
  };

  if (!property) {
    return (
      <span />
    );
  }

  return (
    <IonContent
      className="page-resident-main page-community"
      scrollY={false}
    >
      <HeaderToolbar
        centerTitle={property?.Name}
      />
      <IonContent
        className="content-container"
      >
        <IonLoading
          isOpen={isLoading}
          message={'Loading...'}
        />
        <IonTabs className="sub-tabs">
          <IonRouterOutlet>
            <Route
              path={`/communities/:communityId/gallery`}
              render={() => <ResidentGallery {...childProps} />}
              exact
            />
            <Route
              path={`/communities/:communityId/size-price`}
              render={() => <ResidentSizePrice {...childProps} />}
              exact
            />
            <Route
              path={`/communities/:communityId/about`}
              render={() => <CommunityAbout {...childProps} />}
              exact
            />
            <Route
              path={`/communities/:communityId/features`}
              render={() => <ResidentFeatures {...childProps} />}
              exact
            />
            <Route
              path={`/communities/:communityId/interested`}
              render={() => <CommunityInterested {...childProps} />}
              exact
            />
            <Route
              path={`/communities/:communityId`}
              render={() => <CommunityAbout {...childProps} />}
              exact
            />
          </IonRouterOutlet>
          <IonTabBar
            slot="top"
            onIonTabsDidChange={async (tabEvent: any) => {
              const { tab } = tabEvent.detail;
              if (['http', 'tel', 'email'].some((urlStart) => tab.includes(urlStart))) {
                await Browser.open({ url: tab });
              }
            }}
          >
            <IonTabButton
              tab="community"
              href={`/communities/${communityId}/about`}
            >
              About
            </IonTabButton>
            <IonTabButton
              tab="communitySizePrice"
              href={`/communities/${communityId}/size-price`}
            >
              Size/Price
            </IonTabButton>
            <IonTabButton
              tab="communityFeatures"
              href={`/communities/${communityId}/features`}
            >
              Features
            </IonTabButton>
            <IonTabButton
              tab="communityGallery"
              href={`/communities/${communityId}/gallery`}
            >
              Gallery
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonContent>
      <Tabs
        tabs={[
          {
            name: 'Interested',
            faIcon: faBookmark,
            navigateTo: `/communities/${property?.Id}/interested`
          },
          {
            name: 'Apply',
            faIcon: faFilePen,
            href: `${APP_URL}/apply-for-residency/?property_id=${communityId}`
          },
          {
            name: 'Call Us',
            faIcon: faPhoneOffice,
            href: `tel:${property?.Phone}`
          },
          {
            name: 'Visit Site',
            faIcon: faWindow,
            href: property?.PropertyURL
          },
        ]}
      />
    </IonContent>
  );
}

export default Community;
