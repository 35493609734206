import { faArrowLeft, faCaretLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonButtons, IonHeader, IonMenuButton, IonRouterLink, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import useAuth from "src/hooks/useAuth";
import './HeaderToolbar.css';

interface HeaderToolbarProps {
  headerTitle?: string;
  headerTitleIcon?: JSX.Element;
  backRouterLink?: string;
  changeLogoToBack?: boolean;
  hideBackButton?: boolean;
  centerTitle?: string;
}

const HeaderToolbar: React.FC<HeaderToolbarProps> = (
  {
    headerTitle,
    headerTitleIcon,
    backRouterLink = '',
    changeLogoToBack,
    hideBackButton = false,
    centerTitle = ''
  }) => {

  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const headerTitleComponent = (
    <>
      {
        headerTitle && (
          <IonText className="header-title" color="light">
            <h4>
              {headerTitleIcon}
              {headerTitle}
            </h4>
          </IonText>
        )
      }
    </>
  );

  return (
    <>
      <IonHeader className={`header-main ${headerTitle ? 'ion-no-border' : ''}`}>
        <IonToolbar className="header-toolbar-main">
          {
            isAuthenticated
              ? (
                /* Authenticated */
                <>
                  {
                    !hideBackButton && (
                      <IonButtons slot="start">
                        {
                          changeLogoToBack
                            ? (
                              <IonButton
                                color="light"
                                routerLink={backRouterLink}
                              >
                                <FontAwesomeIcon icon={faArrowLeft} />
                              </IonButton>
                            )
                            :
                            (
                              <IonButton
                                routerLink={"/app"}
                                color="light"
                                className="btn-home"
                              >
                                Slavik
                              </IonButton>
                            )
                        }
                      </IonButtons>
                    )}
                  {centerTitle && (<IonTitle>{centerTitle}</IonTitle>)}
                  <IonButtons slot="end">
                    <IonMenuButton color="light" />
                  </IonButtons>
                </>
              )
              :
              (
                /* Guest */
                <>
                  {
                    !hideBackButton && location.pathname !== '/' && (
                      <IonButtons slot="start">
                        <IonButton
                          color="light"
                          onClick={() => {
                            if (location.pathname === '/login') {
                              history.replace('/resident-splash');
                            } else if (location.pathname === '/resident-splash') {
                              history.replace('/');
                            } else {
                              history.goBack();
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </IonButton>
                      </IonButtons>
                    )
                  }
                  {centerTitle && (<IonTitle>{centerTitle}</IonTitle>)}
                  <IonButtons slot={location.pathname !== '/' ? 'end' : 'start'}>
                    <IonButton
                      routerLink={"/"}
                      color="light"
                      className="btn-home"
                    >
                      Slavik
                    </IonButton>
                  </IonButtons>
                </>
              )
          }
        </IonToolbar>
        {
          backRouterLink && !changeLogoToBack
            ?
            (
              <>
                <div className="header-title-container">
                  <div className="col-back">
                    <IonButton
                      routerDirection="back"
                      routerLink={backRouterLink}
                      className="btn-back"
                      color="light"
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </IonButton>
                  </div>
                  <div className="col-header-title">
                    {headerTitleComponent}
                  </div>
                  <div>
                    <div className="spacer" />
                  </div>
                </div>
              </>
            )
            : headerTitleComponent
        }
      </IonHeader>
    </>
  );
};

export default HeaderToolbar;
