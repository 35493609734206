import { IonContent } from "@ionic/react";
import { FC } from "react";
import ResidentAbout from "src/pages/app/ResidentMain/ResidentAbout";

interface CommunityAboutProps {
  property: any;
}
const CommunityAbout: FC<CommunityAboutProps> = (props) => {
  return (
    <>
      <ResidentAbout {...props} />
    </>
  )
}
export default CommunityAbout;
