import { IonButton, IonContent, IonImg, IonPage, IonRouterLink, IonText } from "@ionic/react";
import HeaderToolbar from "src/components/HeaderToolbar/HeaderToolbar";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import './CommunitiesSelect.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faLocationDot, faCreditCardFront, faFilePen, faPhoneOffice } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from "src/store";
import Tabs from "src/components/Tabs";
import { useEffect, useState } from "react";

const SlideItem = ({ LogoURL, FeaturedImage, Address, Name, Id }: {
  LogoURL: string;
  FeaturedImage: any;
  Address: any;
  Name: string;
  Id: number;
}) => {
  return (
    <>
      <div className="slide-item">
        <div className="logo">
          <IonRouterLink routerLink={`/communities/${Id}`}>
            <IonImg src={LogoURL} className="logo" />
          </IonRouterLink>
        </div>
        <div className="img-bg-wrap">
          <IonRouterLink routerLink={`/communities/${Id}`}>
            <div className="img-bg" style={{
              backgroundImage: `url(${FeaturedImage.url})`
            }}>
              <IonText className="location">
                <FontAwesomeIcon icon={faLocationDot} />
                {`${Address.Line1}${Address.Line2 ? ' ' + Address.Line2 : ''}, ${Address.City}, ${Address.State} ${Address.Zip}`}
              </IonText>
            </div>
          </IonRouterLink>
        </div>
        <div className="mt-4 btn-wrap">
          <IonRouterLink routerLink={`/communities/${Id}`}>
            <IonButton color="primary">
              Explore {Name}
            </IonButton>
          </IonRouterLink>
        </div>
      </div>
    </>
  );
}

const CommunitiesSelect = () => {
  const { properties } = useSelector((state) => state.appData);
  const [property, setSelectedProperty] = useState(null);

  useEffect(() => {
    setSelectedProperty(properties?.[0]);
  }, []);

  return (
    <IonPage className="page-communities">
      <HeaderToolbar
        centerTitle="Our Communities"
      />
      <IonContent
        fullscreen
        className="content-container"
      >
        <div className="mt-3">
          <Swiper
            spaceBetween={9}
            slidesPerView="auto"
            pagination
            modules={[Pagination]}
            centeredSlides
            onSlideChange={(slide) => {
              setSelectedProperty(properties[slide.activeIndex]);
            }}
          >
            {
              properties.map((property, idx) => (
                <SwiperSlide key={idx}>
                  <SlideItem {...property} />
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </IonContent>
      <Tabs
        tabs={[
          {
            name: 'Interested',
            faIcon: faBookmark,
            navigateTo: `/communities/${property?.Id}/interested`
          },
          {
            name: 'Apply',
            faIcon: faFilePen,
            href: property?.PropertyURL
          },
          {
            name: 'Call Us',
            faIcon: faPhoneOffice,
            href: `tel:${property?.Phone}`
          },
          {
            name: 'Visit Site',
            faIcon: faCreditCardFront,
            href: property?.PropertyURL
          },
        ]}
      />
    </IonPage>
  )
};

export default CommunitiesSelect;
