import { InputChangeEventDetail, IonButton, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonNote, IonPage, IonSelect, IonSelectOption, IonSpinner, useIonToast } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import "./Profile.css";
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import { apiGetProperty } from 'src/api/properties';
import { eye, eyeOff } from 'ionicons/icons';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { apiPost } from 'src/api/api';
import { useHistory } from 'react-router';
import awaitCallback from 'src/utils/awaitCallback';

const Profile: React.FC = () => {
  const { user: { user, cookie }, logout } = useAuth();
  const history = useHistory();
  const { properties } = useSelector((state) => state.appData);
  const formRef = useRef<HTMLFormElement>(null);
  const { update } = useAuth();
  const [present] = useIonToast();
  const [formValues, setFormValues] = useState({
    display_name: '',
    phone_number: '',
    email: '',
    propery_id: '', /* community id */
    unit_id: '',
    password: null,
    confirm_password: null
  });
  const defaultFormErrors = {
    display_name: [],
    phone_number: [],
    email: [],
    propery_id: [],
    unit_id: [],
    password: [],
    confirm_password: []
  };
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [units, setUnits] = useState<any[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isOpenModalDeleteAccount, setIsOpenModalDeleteAccount] = useState<boolean>(false);
  const [confirmDeleteText, setConfirmDeleteText] = useState<string>('');
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);

  const navigate = (to) => history.push(to);
  const {
    display_name,
    phone_number,
    email,
    propery_id,
    unit_id,
    password,
    confirm_password
  } = formValues;

  const setFormFieldValue = (key, value) => setFormValues({ ...formValues, [key]: value });

  const schema = yup.object().shape({
    display_name: yup
      .string()
      .required('Display name is required'),
    phone_number: yup
      .string()
      .matches(/^[0-9]{10}$/, 'Invalid phone number')
      .required('Phone is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    propery_id: yup
      .string()
      .required('Please select community'),
    unit_id: yup
      .string()
      .required('Unit number is required'),
    password: yup
      .string()
      .nullable()
      .min(8, 'Password must bt at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must contain uppercase, lowercase, special character and number')
      .oneOf([yup.ref('confirm_password'), null], 'Passwords must match'),
    confirm_password: yup
      .string()
      .nullable()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const getProperty = async (propery_id) => {
    setIsLoading(true);
    try {
      const property = await apiGetProperty(propery_id);
      setUnits(property?.data?.Units);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (propery_id) {
      getProperty(propery_id);
    }
  }, [propery_id]);

  useEffect(() => {
    if (user) {
      setFormValues({
        display_name: user.display_name,
        phone_number: user.phone,
        email: user.email,
        propery_id: user.unit.Property.Id,
        unit_id: user.unit.Id,
        password,
        confirm_password
      })
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    /* reset errors */
    setFormErrors(defaultFormErrors);
    setIsSubmitting(true);
    let valid = true;
    schema.validate(formValues, { abortEarly: false }).catch((error) => {
      let formValidationErrors = defaultFormErrors;

      error.inner.forEach((error: any) => {
        formValidationErrors[error.path] = [
          ...formValidationErrors[error.path],
          error.message
        ]
      });
      setFormErrors(formValidationErrors);

      valid = false;

    }).then(async () => {

      if (valid) {
        // api call here
        try {
          let append = {};
          if (password) {
            append = {
              ...append,
              password
            };
          }

          const response = await update({
            display_name,
            phone: phone_number,
            email,
            community_id: propery_id,
            unit_id,
            cookie,
            ...append
          }) as any;

          present({
            message: 'Profile updated',
            duration: 3000,
            color: 'success'
          });
          setIsSubmitting(false);
        } catch (error) {
          present({
            message: error,
            duration: 3000,
            color: 'danger'
          });
          setIsSubmitting(false);
        }

      } else {
        // not valid, allow submit again
        setIsSubmitting(false);
      }

    });
  };

  const DeleteConfirmComponent = <>
    <p>Are you sure you want to delete your account? Deleting your accounts means you can no longer access it forever.</p>
    <p>Please type "confirm" to continue:</p>
    <IonList>
      <IonItem className="item-has-value item-confirm-delete">
        <IonInput
          autofocus={isOpenModalDeleteAccount}
          onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
            const { value } = e.target as HTMLInputElement;
            setConfirmDeleteText(value);
          }}
          className="input-confirm-delete"
        />
      </IonItem>
    </IonList>
  </>

  return (
    <IonPage className="page-resident-profile">
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <IonLoading
          isOpen={isLoading}
          message={'Loading...'}
        />
        <div className="main-content">
          <h1>Profile</h1>
          {/* For later */}
          {/* <IonLoading isOpen={showLoading} message="Logging in..." onDidDismiss={() => setShowLoading(false)} /> */}
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            method="post"
            action=""
            noValidate
          >
            <IonList>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Display Name*</IonLabel>
                <IonInput
                  value={display_name}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('display_name', value);
                  }}
                  placeholder="Display Name*"
                />
                {
                  formErrors.display_name.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.display_name.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Phone Number*</IonLabel>
                <IonInput
                  value={phone_number}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('phone_number', value);
                  }}
                  placeholder="Phone Number*"
                />
                {
                  formErrors.phone_number.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.phone_number.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Email Address*</IonLabel>
                <IonInput
                  disabled
                  type="email"
                  value={email}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('email', value);
                  }}
                  placeholder="Email Address*"
                />
                {
                  formErrors.email.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.email.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Select Community*</IonLabel>
                <IonSelect
                  value={propery_id}
                  okText="Select"
                  cancelText="Dismiss"
                  onIonChange={e => {
                    setFormFieldValue('propery_id', e.detail.value);
                  }}
                >
                  {
                    properties.map((property) => (
                      <IonSelectOption
                        value={property.Id}
                        key={property.Id}
                      >
                        {property.Name}
                      </IonSelectOption>
                    ))
                  }
                </IonSelect>
                {
                  formErrors.email.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.email.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Unit No.*</IonLabel>
                <IonSelect
                  value={units.length ? unit_id : ''}
                  okText="Select"
                  cancelText="Dismiss"
                  onIonChange={e => {
                    setFormFieldValue('unit_id', e.detail.value);
                  }}
                >
                  {
                    units.map((unit) => (
                      <IonSelectOption
                        value={unit.Id}
                        key={unit.Id}
                      >
                        {unit.UnitNo}
                      </IonSelectOption>
                    ))
                  }
                </IonSelect>
                {
                  formErrors.unit_id.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.unit_id.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <div className="item-with-action">
                  <div className="col-input">
                    <IonLabel position="floating">New Password*</IonLabel>
                    <IonInput
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                        const { value } = e.target as HTMLInputElement;
                        setFormFieldValue('password', value);
                      }}
                      placeholder="Password*"
                    />
                  </div>
                  <div className="col-action">
                    <a
                      href="#"
                      className="btn-icon-only dark"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={showPassword ? eye : eyeOff}
                      />
                    </a>
                  </div>
                </div>
                {
                  formErrors.password.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.password.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <div className="item-with-action">
                  <div className="col-input">
                    <IonLabel position="floating">Submit Password</IonLabel>
                    <IonInput
                      type={showPassword ? "text" : "password"}
                      value={confirm_password}
                      onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                        const { value } = e.target as HTMLInputElement;
                        setFormFieldValue('confirm_password', value);
                      }}
                      placeholder="Submit Password"
                    />
                  </div>
                  <div className="col-action">
                    <a
                      href="#"
                      className="btn-icon-only dark"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={showPassword ? eye : eyeOff}
                      />
                    </a>
                  </div>
                </div>
                {
                  formErrors.confirm_password.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.confirm_password.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              {/* hide faceId */}
              {/* <IonItem className="item-checkbox">
                <IonCheckbox
                  slot="start"
                  name="enable_faceid"
                />
                <IonLabel>
                  Enable FaceID
                </IonLabel>
              </IonItem> */}
            </IonList>
            <div className="form-action">
            <div>
                <IonButton
                  color="light"
                  size="default"
                  onClick={() => setIsOpenModalDeleteAccount(true)}
                >
                  Delete Account
                </IonButton>
              </div>
              <div>
                <IonButton
                  type="submit"
                  color="primary"
                  size="default"
                  disabled={isSubmitting}
                >
                  {
                    isSubmitting
                      ? (
                        <IonSpinner name="dots" />
                      )
                      : 'Update Profile'
                  }
                </IonButton>
              </div>
            </div>
          </form>
        </div>
        <ConfirmDialog
          isOpen={isOpenModalDeleteAccount}
          onClose={() => setIsOpenModalDeleteAccount(false)}
          title="Delete account?"
          content={DeleteConfirmComponent}
          onConfirm={async () => {
            setIsDeletingUser(true);
            try {
              await apiPost({
                targetUrl: 'user/delete',
                formData: {
                  cookie
                },
                nonce: {
                  method: 'delete'
                }
              });
              present({
                message: 'User deleted',
                duration: 3000,
                color: 'success'
              });

              await awaitCallback(setIsDeletingUser(false));
              await awaitCallback(setIsOpenModalDeleteAccount(false));
              await logout();
              navigate('/login');
            } catch (error) {
              present({
                message: error,
                duration: 3000,
                color: 'danger'
              });
              setIsDeletingUser(false);
            }
          }}
          confirmButtonText={
            isDeletingUser
              ? (
                <IonSpinner name="dots" />
              )
              : 'Delete Account'
          }
          confirmButtonProps={{
            color: 'danger',
            disabled: confirmDeleteText.toLowerCase() !== 'confirm'
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default Profile;
