import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiGetProperties } from 'src/api/properties';
import { AppThunk } from 'src/store';

export interface AppDataState {
  properties?: any[];
}

const initialState = {
  properties: []
};

const slice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    getAppData(state: AppDataState, action: PayloadAction<any>): void {
      state.properties = action.payload.properties;
    }
  }
});

export const { reducer } = slice;

export const getAppData = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    const propertiesData = await apiGetProperties();

    const data = {
      properties: propertiesData?.data?.Properties.filter((property) => property.Gallery.length) || []
    };
    dispatch(slice.actions.getAppData(data));
  } catch (error) {
    console.error(error);
  }
};
