import axios from 'axios';
import { API_URL } from 'src/config';
import { isEmpty } from 'lodash';

export const apiGet = async ({ targetUrl = '', formData = {} }): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_URL}/${targetUrl}`, { params: formData })
      .then((response: any) => {
        if (response.status >= 200 && response.status <= 299) {
          const { data } = response;

          resolve(data);
        } else {
          reject(new Error(response?.data.status));
        }
      })
      .catch((error) => {
        reject(error?.response?.data.status || new Error('Internal server error'));
      });
  });
}

export const apiPost = async ({
  targetUrl = '',
  formData = {} as object,
  nonce = null as null | { method?: string; controller?: string }
}): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const fd = new FormData();
    if (!isEmpty(formData)) {
      Object.keys(formData).forEach((objKey) => {
        const fdd = formData[objKey];
        if (fdd?.name) {
          // file
          fd.append(objKey, fdd, fdd.name);
        } else {
          fd.append(objKey, fdd);
        }
      })
    }

    if (nonce) {
      const nonceData = await apiGetNonce({
        controller: nonce?.controller || 'user',
        method: nonce?.method,
      });

      fd.append('nonce', nonceData.nonce);
    }

    axios.post(`${API_URL}/${targetUrl}`, fd)
      .then((response: any) => {
        if (response.status >= 200 && response.status <= 299) {
          const { data } = response;

          resolve(data);
        } else {
          reject(new Error(response.data.status));
        }
      })
      .catch((error) => {
        reject(error?.response?.data.status || new Error('Internal server error'));
      });
  });
}

export const apiGetNonce = async ({ targetUrl = 'user/get_nonce/', controller = 'user', method = 'generate_auth_cookie' }): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_URL}/${targetUrl}`,
      {
        params: {
          controller,
          method
        }
      }
    )
      .then((response: any) => {
        if (response.status >= 200 && response.status <= 299) {
          const { data } = response;

          resolve(data);
        } else {
          reject(new Error(response.data));
        }
      })
      .catch((error) => {
        reject(error?.response?.data.status || new Error('Internal server error'));
      });
  });
}
