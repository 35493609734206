import { IonContent, IonSelect, IonSelectOption } from "@ionic/react";
import { FC, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Pagination } from 'swiper';
import { PropertyChildProps } from '../ResidentMain';

// Import Swiper styles
import 'swiper/css';
import './ResidentGallery.css';
import useAuth from "src/hooks/useAuth";

const galleryTypes = [
  {
    value: 'GalleryInterior',
    name: 'Interior'
  },
  {
    value: 'GalleryExterior',
    name: 'Exterior'
  },
  {
    value: 'GalleryFloorPlan',
    name: 'Floor Plans'
  }
];

const SlideItem = ({ src }: {
  src: string;
}) => {
  return (
    <>
      <div className="slide-item-fullscreen">
        <div className="img-bg" style={{
          backgroundImage: `url(${src})`
        }} />
        <img src={src} alt={src} />
      </div>
    </>
  );
}

const ResidentGallery: FC<PropertyChildProps> = (props) => {
  const { property } = props;
  const selectRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperClass>(null);
  const [slides, setSlides] = useState<string[]>([]);
  const [galleryType, setGalleryType] = useState<string>(galleryTypes[0].value);

  const mapGallery = (gallery = []) => gallery.map((pic) => pic?.medium?.url || pic.url);

  useEffect(() => {
    if (galleryType && property) {
      setSlides(
        mapGallery(
          property?.[galleryType]
        )
      );
      // reset slider on change gallery type
      swiperRef.current.slideTo(0);
    }
  }, [galleryType]);

  return (
    <IonContent className="content-resident-gallery content-resident">
      <div className="content-resident-main">
        <div
          className="gallery-selector"
          ref={selectRef}
        >
          <IonSelect
            interfaceOptions={{
              header: 'Select Category'
            }}
            value={galleryType}
            okText="Select"
            cancelText="Dismiss"
            onIonChange={e => {
              setGalleryType(e.detail.value);
            }}
          >
            {
              galleryTypes.map((type) => (
                <IonSelectOption
                  value={type.value}
                  key={type.value}
                >
                  {type.name}
                </IonSelectOption>
              ))
            }
          </IonSelect>
        </div>
        <div
          style={{
            height: `calc(100% - ${selectRef.current?.offsetHeight || 40}px)`
          }}
        >
          <Swiper
            onInit={(el) => swiperRef.current = el}
            spaceBetween={0}
            slidesPerView={1}
            pagination
            modules={[Pagination]}
          >
            {
              slides.map((slide, idx) => (
                <SwiperSlide key={idx}>
                  <SlideItem src={slide} />
                </SwiperSlide>
              ))
            }
          </Swiper>

        </div>
      </div>
    </IonContent>
  );
}

export default ResidentGallery;
