import { InputChangeEventDetail, IonButton, IonContent, IonImg, IonInput, IonItem, IonLabel, IonList, IonNote, IonPage, IonSpinner, IonTextarea, useIonToast } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import "../ReferAFriend/ReferAFriend.css";
import useAuth from 'src/hooks/useAuth';
import { apiPostReferAFriend } from 'src/api/properties';
import { SECRET_KEY } from 'src/config';
import { useHistory } from 'react-router';

const ReferAFriend: React.FC = () => {
  const { user: { user } } = useAuth();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const [present] = useIonToast();
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
    friendName: '',
    friendEmail: ''
  });
  const defaultFormErrors = {
    name: [],
    phone: [],
    email: [],
    subject: [],
    message: [],
    friendName: [],
    friendEmail: []
  };
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    name,
    phone,
    email,
    subject,
    message,
    friendName,
    friendEmail
  } = formValues;

  const setFormFieldValue = (key, value) => setFormValues({ ...formValues, [key]: value });

  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required'),
    phone: yup
      .string()
      .matches(/^[0-9]{10}$/, 'Invalid phone number')
      .required('Phone is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    friendName: yup
      .string()
      .required('Friend\s name is required'),
    friendEmail: yup
      .string()
      .required('Friend\s email is required'),
    subject: yup
      .string()
      .required('Subject is required'),
    message: yup
      .string()
      .required('Message is required'),
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        name: user.display_name,
        phone: user.phone,
        email: user.email,
        subject: '',
        message: '',
        friendName: '',
        friendEmail: ''
      })
    }
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    /* reset errors */
    setFormErrors(defaultFormErrors);
    setIsSubmitting(true);
    let valid = true;
    schema.validate(formValues, { abortEarly: false }).catch((error) => {
      let formValidationErrors = defaultFormErrors;

      error.inner.forEach((error: any) => {
        formValidationErrors[error.path] = [
          ...formValidationErrors[error.path],
          error.message
        ]
      });
      setFormErrors(formValidationErrors);

      setTimeout(function () {
        formRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 300);

      valid = false;

    }).then(async () => {

      if (valid) {
        // api call here
        try {
          const response = await apiPostReferAFriend({
            unitId: user?.unit.Id,
            formData: {
              Secret_Key: SECRET_KEY,
              Name: name,
              Phone: phone,
              Email: email,
              Subject: subject,
              Message: message,
              FriendName: friendName,
              FriendEmail: friendEmail
            }
          }) as any;

          present({
            message: 'Success',
            duration: 3000,
            color: 'success'
          });
          setIsSubmitting(false);
          history.goBack();
        } catch (error) {
          present({
            message: error,
            duration: 3000,
            color: 'danger'
          });
          setIsSubmitting(false);
        }

      } else {
        // not valid, allow submit again
        setIsSubmitting(false);
      }

    });
  };

  return (
    <IonPage className="page-refer-a-friend">
      <HeaderToolbar changeLogoToBack={true} />
      <IonContent
        fullscreen
        className="content-container"
      >
        <div className="main-content">
          <h1>Refer a Friend</h1>
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            method="post"
            action=""
            noValidate
          >
            <IonList>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Name*</IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('name', value);
                  }}
                  placeholder="Name*"
                />
                {
                  formErrors.name.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.name.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Phone*</IonLabel>
                <IonInput
                  value={phone}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('phone', value);
                  }}
                  placeholder="Phone*"
                />
                {
                  formErrors.phone.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.phone.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Email*</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('email', value);
                  }}
                  placeholder="Email*"
                />
                {
                  formErrors.email.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.email.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Friend Name*</IonLabel>
                <IonInput
                  value={friendName}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('friendName', value);
                  }}
                  placeholder="Friend Name*"
                />
                {
                  formErrors.friendName.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.friendName.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Friend Email*</IonLabel>
                <IonInput
                  type="email"
                  value={friendEmail}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('friendEmail', value);
                  }}
                  placeholder="Friend Email*"
                />
                {
                  formErrors.friendEmail.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.friendEmail.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Subject*</IonLabel>
                <IonInput
                  value={subject}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('subject', value);
                  }}
                  placeholder="Subject*"
                />
                {
                  formErrors.subject.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.subject.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Message*</IonLabel>
                <IonTextarea
                  value={message}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('message', value);
                  }}
                  placeholder="Message*"
                  rows={7}
                />
                {
                  formErrors.message.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.message.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
            </IonList>
            <div className="form-action">
              <IonButton
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                {
                  isSubmitting
                    ? (
                      <IonSpinner name="dots" />
                    )
                    : 'Submit'
                }
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default ReferAFriend;
