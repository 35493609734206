import { InputChangeEventDetail, IonButton, IonCheckbox, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonNote, IonPage, IonSelect, IonSelectOption, IonSpinner, useIonToast } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import HeaderToolbar from 'src/components/HeaderToolbar/HeaderToolbar';
import { useHistory } from 'react-router';
import "./ResidentRegister.css";
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import { apiGetProperty } from 'src/api/properties';
import { Browser } from '@capacitor/browser';
import { APP_URL } from 'src/config';
import { eye, eyeOff } from 'ionicons/icons';

const ResidentRegister: React.FC = () => {
  const { properties } = useSelector((state) => state.appData);
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const { register } = useAuth();
  const [present] = useIonToast();
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    propery_id: '', /* community id */
    unit_id: '',
    password: '',
    confirm_password: '',
    tnc_agree: false
  });
  const defaultFormErrors = {
    first_name: [],
    last_name: [],
    phone_number: [],
    email: [],
    propery_id: [],
    unit_id: [],
    password: [],
    confirm_password: [],
    tnc_agree: []
  };
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [units, setUnits] = useState<any[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    first_name,
    last_name,
    phone_number,
    email,
    propery_id,
    unit_id,
    password,
    confirm_password,
    tnc_agree
  } = formValues;

  const setFormFieldValue = (key, value) => setFormValues({ ...formValues, [key]: value });

  const navigate = (to) => history.push(to);

  const schema = yup.object().shape({
    first_name: yup
      .string()
      .required('First name is required'),
    last_name: yup
      .string()
      .required('Last name is required'),
    phone_number: yup
      .string()
      .matches(/^[0-9]{10}$/, 'Invalid phone number')
      .required('Phone is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    propery_id: yup
      .string()
      .required('Please select community'),
    unit_id: yup
      .string()
      .required('Unit number is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must bt at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must contain uppercase, lowercase, special character and number')
      .oneOf([yup.ref('confirm_password'), null], 'Passwords must match'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    tnc_agree: yup.boolean().oneOf([true], "Please agree to Terms & Conditions")
  });

  const getProperty = async (propery_id) => {
    setIsLoading(true);
    try {
      const property = await apiGetProperty(propery_id);
      setUnits(property?.data?.Units);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (propery_id) {
      getProperty(propery_id);
    }
  }, [propery_id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    /* reset errors */
    setFormErrors(defaultFormErrors);
    setIsSubmitting(true);
    let valid = true;
    schema.validate(formValues, { abortEarly: false }).catch((error) => {
      let formValidationErrors = defaultFormErrors;

      error.inner.forEach((error: any) => {
        formValidationErrors[error.path] = [
          ...formValidationErrors[error.path],
          error.message
        ]
      });
      setFormErrors(formValidationErrors);

      setTimeout(function () {
        formRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 300);

      valid = false;

    }).then(async () => {

      if (valid) {
        // api call here
        try {
          const response = await register({
            display_name: `${first_name} ${last_name}`,
            phone: phone_number,
            email,
            password,
            community_id: propery_id,
            unit_id
          }) as any;

          navigate('/app');
        } catch (error) {
          present({
            message: error,
            duration: 3000,
            color: 'danger'
          });
          setIsSubmitting(false);
        }

      } else {
        // not valid, allow submit again
        setIsSubmitting(false);
      }

    });
  }

  return (
    <IonPage className="page-resident-register">
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <IonLoading
          isOpen={isLoading}
          message={'Loading...'}
        />
        <div className="main-content">
          <h1>New Resident</h1>
          {/* For later */}
          {/* <IonLoading isOpen={showLoading} message="Logging in..." onDidDismiss={() => setShowLoading(false)} /> */}
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            method="post"
            action=""
            noValidate
          >
            <IonList>
              <IonItem aria-errormessage="Error">
                <IonLabel position="floating">First Name*</IonLabel>
                <IonInput
                  value={first_name}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('first_name', value);
                  }}
                  placeholder="First Name*"
                />
                {
                  formErrors.first_name.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.first_name.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className={last_name && 'item-has-focus'}>
                <IonLabel position="floating">Last Name*</IonLabel>
                <IonInput
                  value={last_name}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('last_name', value);
                  }}
                  placeholder="Last Name*"
                />
                {
                  formErrors.last_name.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.last_name.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Phone Number*</IonLabel>
                <IonInput
                  value={phone_number}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('phone_number', value);
                  }}
                  placeholder="Phone Number*"
                />
                {
                  formErrors.phone_number.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.phone_number.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Email Address*</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('email', value);
                  }}
                  placeholder="Email Address*"
                />
                {
                  formErrors.email.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.email.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Select Community*</IonLabel>
                <IonSelect
                  value={propery_id}
                  okText="Select"
                  cancelText="Dismiss"
                  onIonChange={e => {
                    setFormFieldValue('propery_id', e.detail.value);
                  }}
                >
                  {
                    properties.map((property) => (
                      <IonSelectOption
                        value={property.Id}
                        key={property.Id}
                      >
                        {property.Name}
                      </IonSelectOption>
                    ))
                  }
                </IonSelect>
                {
                  formErrors.propery_id.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.propery_id.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Unit No.*</IonLabel>
                <IonSelect
                  value={unit_id}
                  okText="Select"
                  cancelText="Dismiss"
                  onIonChange={e => {
                    setFormFieldValue('unit_id', e.detail.value);
                  }}
                >
                  {
                    units.map((unit) => (
                      <IonSelectOption
                        value={unit.Id}
                        key={unit.Id}
                      >
                        {unit.UnitNo}
                      </IonSelectOption>
                    ))
                  }
                </IonSelect>
                {
                  formErrors.unit_id.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.unit_id.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem>
                <div className="item-with-action">
                  <div className="col-input">
                    <IonLabel position="floating">Password*</IonLabel>
                    <IonInput
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                        const { value } = e.target as HTMLInputElement;
                        setFormFieldValue('password', value);
                      }}
                      placeholder="Password*"
                    />
                  </div>
                  <div className="col-action">
                    <a
                      href="#"
                      className="btn-icon-only dark"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={showPassword ? eye : eyeOff}
                      />
                    </a>
                  </div>
                </div>
                {
                  formErrors.password.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.password.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem>
                <div className="item-with-action">
                  <div className="col-input">
                    <IonLabel position="floating">Submit Password</IonLabel>
                    <IonInput
                      type={showPassword ? "text" : "password"}
                      value={confirm_password}
                      onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                        const { value } = e.target as HTMLInputElement;
                        setFormFieldValue('confirm_password', value);
                      }}
                      placeholder="Submit Password"
                    />
                  </div>
                  <div className="col-action">
                    <a
                      href="#"
                      className="btn-icon-only dark"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={showPassword ? eye : eyeOff}
                      />
                    </a>
                  </div>
                </div>
                {
                  formErrors.confirm_password.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.confirm_password.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-checkbox no-mb-i">
                <IonCheckbox
                  checked={tnc_agree}
                  slot="start"
                  onIonChange={(e) => {
                    setFormFieldValue('tnc_agree', e.detail.checked);
                  }}
                />
                <IonLabel>
                  I Agree to Terms &amp; Conditions.
                </IonLabel>
                {
                  formErrors.tnc_agree.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.tnc_agree.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-link">
                <a
                  href={`${APP_URL}/privacy-policy/`}
                  onClick={async (e) => {
                    e.preventDefault();
                    await Browser.open({ url: `${APP_URL}/privacy-policy/` });
                  }}
                >
                  Privacy Policy
                </a>
              </IonItem>
              {/* hide faceId */}
              {/* <IonItem className="item-checkbox">
                <IonCheckbox
                  slot="start"
                  name="enable_faceid"
                />
                <IonLabel>
                  Enable FaceID
                </IonLabel>
              </IonItem> */}
            </IonList>
            <div className="form-action">
              <IonButton
                type="submit"
                color="primary"
                size="small"
                disabled={isSubmitting}
              >
                {
                  isSubmitting
                    ? (
                      <IonSpinner name="dots" />
                    )
                    : 'Submit'
                }
              </IonButton>
              <IonImg className="logo" src={'assets/images/slavikLogoBunker.png'} />
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default ResidentRegister;
