import { InputChangeEventDetail, IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonNote, IonSpinner, IonTextarea, useIonToast } from '@ionic/react';
import { FC, useRef, useState } from 'react';
import * as yup from 'yup';
import "../../app/Profile/Profile.css";
import "./Interested.css";
import { SECRET_KEY } from 'src/config';
import { PropertyChildProps } from 'src/pages/app/ResidentMain/ResidentMain';
import { apiPostPropertyInterested } from 'src/api/properties';
import { useHistory } from 'react-router';

const initialValues = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip_code: '',
  phone: '',
  email: '',
  move_in_date: new Date().toISOString(),
  message: ''
};

const CommunityInterested: FC<PropertyChildProps> = (props) => {
  const history = useHistory();
  const { property } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const [present] = useIonToast();
  const [formValues, setFormValues] = useState(initialValues);
  const defaultFormErrors = {
    name: [],
    address1: [],
    address2: [],
    city: [],
    state: [],
    zip_code: [],
    phone: [],
    email: [],
    move_in_date: [],
    message: []
  };
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    name,
    address1,
    address2,
    city,
    state,
    zip_code,
    phone,
    email,
    move_in_date,
    message
  } = formValues;

  const setFormFieldValue = (key, value) => setFormValues({ ...formValues, [key]: value });

  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Name is required'),
    address1: yup
      .string()
      .required('Address is required'),
    address2: yup
      .string(),
    city: yup
      .string()
      .required('City is Required'),
    state: yup
      .string()
      .required('State is Required'),
    zip_code: yup
      .string()
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Invalid zip code')
      .required('Zip code is required'),
    phone: yup
      .string()
      .matches(/^[0-9]{10}$/, 'Invalid phone number')
      .required('Phone is Required'),
    email: yup
      .string()
      .email('Invalid email.')
      .required(' is Required'),
    move_in_date: yup
      .string()
      .required('Move in date is Required'),
    message: yup
      .string()
      .required('Message is Required')
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    /* reset errors */
    setFormErrors(defaultFormErrors);
    setIsSubmitting(true);
    let valid = true;
    schema.validate(formValues, { abortEarly: false }).catch((error) => {
      let formValidationErrors = defaultFormErrors;

      error.inner.forEach((error: any) => {
        formValidationErrors[error.path] = [
          ...formValidationErrors[error.path],
          error.message
        ]
      });
      setFormErrors(formValidationErrors);

      valid = false;

    }).then(async () => {

      if (valid) {
        // api call here
        try {
          const response = await apiPostPropertyInterested({
            formData: {
              Secret_Key: SECRET_KEY,
              CommunityId: property.Id,
              Name: name,
              StreetAddress: address1,
              StreetAddress2: address2,
              City: city,
              State: state,
              ZipCode: zip_code,
              Phone: phone,
              Email: email,
              MoveInDate: move_in_date,
              InterestedComments: message
            }
          }) as any;

          present({
            message: 'Successfully sent your request',
            duration: 3000,
            color: 'success'
          });
          setIsSubmitting(false);
          setFormValues(initialValues);
          history.goBack();
        } catch (error) {
          present({
            message: error,
            duration: 3000,
            color: 'danger'
          });
          setIsSubmitting(false);
        }

      } else {
        // not valid, allow submit again
        setIsSubmitting(false);
      }

    });
  };

  return (
    <IonContent className="content-resident-about content-resident page-resident-profile page-interest">
      <div className="content-resident-main content-container">
        <div className="main-content">
          <h1>Interested</h1>
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            method="post"
            action=""
            noValidate
          >
            <IonList>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Name*</IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('name', value);
                  }}
                  placeholder="Name*"
                />
                {
                  formErrors.name.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.name.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Address Line 1*</IonLabel>
                <IonInput
                  value={address1}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('address1', value);
                  }}
                  placeholder="Address Line 1*"
                />
                {
                  formErrors.address1.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.address1.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Address Line 2</IonLabel>
                <IonInput
                  value={address2}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('address2', value);
                  }}
                  placeholder="Address Line 2"
                />
                {
                  formErrors.address2.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.address2.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">City*</IonLabel>
                <IonInput
                  value={city}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('city', value);
                  }}
                  placeholder="City*"
                />
                {
                  formErrors.city.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.city.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">State*</IonLabel>
                <IonInput
                  value={state}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('state', value);
                  }}
                  placeholder="State*"
                />
                {
                  formErrors.state.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.state.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Zip Code*</IonLabel>
                <IonInput
                  value={zip_code}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('zip_code', value);
                  }}
                  placeholder="Zip Code*"
                />
                {
                  formErrors.zip_code.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.zip_code.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Phone*</IonLabel>
                <IonInput
                  value={phone}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('phone', value);
                  }}
                  placeholder="Phone*"
                />
                {
                  formErrors.phone.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.phone.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Email*</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('email', value);
                  }}
                  placeholder="Email*"
                />
                {
                  formErrors.email.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.email.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Move in Date*</IonLabel>
                <IonInput
                  type="date"
                  value={move_in_date}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('move_in_date', value);
                  }}
                  placeholder="Move in Date*"
                />
                {
                  formErrors.move_in_date.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.move_in_date.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
              <IonItem className="item-has-value">
                <IonLabel position="floating">Message*</IonLabel>
                <IonTextarea
                  value={message}
                  onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                    const { value } = e.target as HTMLInputElement;
                    setFormFieldValue('message', value);
                  }}
                  placeholder="Message*"
                  rows={7}
                />
                {
                  formErrors.message.length ? (
                    <IonNote
                      slot="helper"
                      color="danger"
                    >
                      {
                        formErrors.message.map((error, idx) => (
                          <div key={idx}>{error}</div>
                        ))
                      }
                    </IonNote>
                  ) : null
                }
              </IonItem>
            </IonList>
            <div className="form-action">
              <IonButton
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                {
                  isSubmitting
                    ? (
                      <IonSpinner name="dots" />
                    )
                    : 'Submit'
                }
              </IonButton>
            </div>
          </form>
        </div>
      </div>
    </IonContent>
  );
};

export default CommunityInterested;
