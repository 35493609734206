import { IonContent, IonImg, IonPage, useIonViewDidEnter } from "@ionic/react";
import { FC, useEffect } from "react";
import { useHistory } from "react-router";
import HeaderToolbar from "src/components/HeaderToolbar/HeaderToolbar";
import useAuth from "src/hooks/useAuth";

const Logout: FC = () => {
  const { logout } = useAuth();
  const history = useHistory();

  const navigate = (to) => history.push(to);

  const processLogout = async () => {
    await logout();
    navigate('/login');
  };
  useIonViewDidEnter(() => {
    processLogout();
  }, []);

  return (
    <IonPage className="page-welcome guest-screen-bg">
      <HeaderToolbar />
      <IonContent
        fullscreen
        className="content-container"
      >
        <div className="main-content">
          <h1>Logging out...</h1>
        </div>
        <IonImg className="logo" src={'assets/images/slavikLogoWhite.png'} />
      </IonContent>
    </IonPage>
  );
}

export default Logout;
